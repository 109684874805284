import React from 'react'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import H2 from 'components/typography/h2'
import Checklist from 'components/new/checklist'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import Content from 'components/content'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { FaExclamationCircle } from '@react-icons/all-files/fa/FaExclamationCircle'
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle'

const WarningIcon = () => {
  return <StyledWarningIcon aria-hidden='true' focusable='false' role='presentation' />
}

const StyledWarningIcon = styled(FaExclamationCircle)`
  color: ${props => props.theme.color.y500};
  font-size: 20px;
`

const CheckIconCircle = () => {
  return <StyledCheckIconCircle aria-hidden='true' focusable='false' role='presentation' />
}

const StyledCheckIconCircle = styled(FaCheckCircle)`
  color: ${props => props.theme.color.g400};
  font-size: 20px;
`

const PrecisionAgPage = ({ data: { computerImage, heroImage, logo } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Precision Ag Consulting | Hutson Inc</title>
        <meta
          name='description'
          content="Hutson's Precision Ag Team focuses on field-by-field profitability through equipment utilization and agronomy"
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Precision Ag',
                'item': 'https://www.hutsoninc.com/precision-ag/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroImage title='Precision Ag' image={heroImage} />

      <Content>
        <Section>
          <H2>About Precision Ag</H2>
          <div>
            <SubSection style={{ overflow: 'auto' }}>
              <LogoContainer>
                <Logo image={logo.childImageSharp.gatsbyImageData} alt='Ag Data Works logo' />
              </LogoContainer>
              <H3>What is Precision Ag?</H3>
              <p>
                Hutson Precision Ag is a consulting division within Hutson that focuses on field by
                field profitability through equipment utilization and agronomy.
              </p>
            </SubSection>
          </div>
          <SubSection>
            <H3>Why should a farmer use Hutson's Precision Ag Team?</H3>
            <ul>
              <li>
                Increase field by field profitability through program management specifically
                designed for you and your operation
              </li>
              <li>
                Hutson's Precision Ag Team provides education of the latest technology trends that
                can help your bottom line
              </li>
              <li>
                By combining our 30-year proprietary data and your data, Hutson's Precision Ag Team
                can help manage risks by providing you greater visibility of your operation
              </li>
              <li>
                Hutson's Precision Ag programs save you time and helps eliminate the guess work of
                farming
              </li>
            </ul>
          </SubSection>

          <SubSection>
            <H3>What makes Hutson's Precision Ag team different from the rest?</H3>
            <ul>
              <li>
                You’ll get two specialists to help advise; an agronomist and precision specialist
              </li>
              <li>
                Being part of the Hutson organization, we provide a great amount of equipment
                knowledge
              </li>
              <li>
                We help manage your fields based on your historical field data. No starting from
                scratch
              </li>
              <li>Hutson's Precision Ag Team has 30-years of propriety satellite imagery</li>
              <li>
                We manage field profitability using a managementzone system over the traditional
                grid in order to provide higher accuracy of input placement
              </li>
            </ul>
          </SubSection>
        </Section>

        <Section>
          <H2>Data Management Solutions</H2>
          <p>
            Hutson's data management program is a custom program for your operation that is designed
            to help allocate your agronomic inputs. By placing the right amount of inputs in the
            correct places, we are able to deliver a higher return on investment for your operation.
            The program allows operators to save time by not having to worry about field by field
            settings. Our standardized process decreases the liability of having corrupt data,
            giving you peace of mind. Our service includes:
          </p>
          <StyledChecklist>
            <>Preloading monitors</>
            <>Uploading updated boundaries</>
            <>Uploading hybrid/variety names</>
            <>Uploading farm, field, and guidance lines</>
          </StyledChecklist>
        </Section>

        <Section>
          <H2>Farm Management Software</H2>
          <p>
            New to farm management software? Our system will help you manage different aspects of
            your business, giving you a complete view of your operation which will allow you to make
            better decisions for your farm. Being a cloud based app, our system has the ability to
            ‘talk to each other’. This means that data generated from one system is automatically
            passed to the other. Our software stores planting, harvest, inputs, and soil sampling
            data.
          </p>
          <StyledChecklist>
            <>Works with majority of monitors on the market</>
            <>Predictive weather functions assist in planning</>
            <>Create and send work orders</>
            <>Zone management for higher accuracy</>
            <>Asset tracking</>
            <>Local phone support is always available</>
            <>Two local training sessions per year</>
          </StyledChecklist>
          <ComputerImg
            image={computerImage.childImageSharp.gatsbyImageData}
            alt='Screenshot of Farm Management Software'
          />
        </Section>

        <Section>
          <H2>Whole Farm Program</H2>
          <p>
            Allow your operation to reach its full potential. The whole farm program assigns a
            precision specialist and agronomist consultants to your operation. These professionals
            work together to bring innovation and solutions to the real challenges that your
            operation faces. This is a start to finish program that will take all the hassle out of
            preseason planning, operator training and in-field scouting. This allows you to do what
            you do best and focus on the overall management of your farm.
          </p>
        </Section>

        <PackagesTable>
          <thead>
            <tr>
              <th>Soybean Programs</th>
              <th>Gold</th>
              <th>Platinum</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Access to Hutson agronomist and precision specialist at all times for fields under
                contract
              </td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Technology support for your application equipment</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Yield and as-applied data analysis</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Seeding recommendations</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Fertility recommendations</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>In-field planter setup</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Combine calibration</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Data management for fields under contract</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>
                Scouting (stand counts, early season weed pressure identification and
                fungicide/insecticide recommendations)
              </td>
              <td></td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Tissue sampling for fields under contact</td>
              <td></td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Prescriptions for lime, fertilizer and planting operations</td>
              <td></td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
          </tbody>
        </PackagesTable>
        <PackagesTable>
          <thead>
            <tr>
              <th>Corn Programs</th>
              <th>Gold</th>
              <th>Platinum</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Access to Hutson agronomist and precision specialist at all times for fields under
                contract
              </td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Technology support for your application equipment</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Yield and as-applied data analysis</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Zone soil testing</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Seeding recommendations</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Fertility recommendations</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>In-field planter setup</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Combine calibration</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Data management for fields under contract</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Prescriptions for lime and fertilizer operations</td>
              <td>
                <CheckIconCircle />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Prescriptions for planting and nitrogen operations</td>
              <td>
                <WarningIcon />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>
                Scouting (stand counts, early season weed pressure identification and
                fungicide/insecticide recommendations)
              </td>
              <td>
                <WarningIcon />
              </td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Tissue sampling for fields under contact</td>
              <td></td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Seed meter testing (if needed)</td>
              <td></td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
            <tr>
              <td>Zone creation</td>
              <td></td>
              <td>
                <CheckIconCircle />
              </td>
            </tr>
          </tbody>
        </PackagesTable>
        <NoteContainer>
          <WarningIcon /> <Note>Choose either scouting or planting and nitrogen prescriptions</Note>
        </NoteContainer>
      </Content>
    </Layout>
  )
}

const Section = styled.div`
  h3 + p {
    margin-top: 10px;
  }

  ul {
    list-style: bullet;
    margin-top: 10px;

    li {
      margin-bottom: 10px;
    }
  }

  :not(:first-child) {
    margin: 40px 0 0;

    @media (min-width: 800px) {
      margin: 80px 0 0;
    }
  }
`

const SubSection = styled.div`
  margin-bottom: 30px;
`

const H3 = styled.h3`
  font-size: 1.25em;
  font-weight: bold;
  margin: 0;
  text-transform: none;
`

const LogoContainer = styled.div`
  margin-bottom: 30px;

  @media (min-width: 800px) {
    display: inline-block;
    float: right;
    margin-bottom: 0;
    margin-left: 30px;
    text-align: right;
    vertical-align: middle;
  }
`

const Logo = styled(GatsbyImage)`
  max-width: 300px;
  width: 100%;
`

const StyledChecklist = styled(Checklist)`
  padding: 0;

  svg {
    font-size: 1.1em;
  }

  @media (min-width: 800px) {
    ${clearfix}

    li {
      ${column('1/2')}
    }
  }
`

const ComputerImg = styled(GatsbyImage)`
  margin-top: 30px;
  max-width: 400px;
  width: 100%;
`

const PackagesTable = styled.table`
  border: none;
  border-collapse: collapse;
  display: block;
  margin-top: 30px;
  overflow-x: auto;
  width: 100%;

  td,
  th {
    border: 1px solid #fff;
  }

  thead {
    th {
      font-size: 1.1em;
      white-space: nowrap;

      :nth-child(1) {
        background-color: #000;
        color: #fff;
        text-align: left;
      }

      :nth-child(2) {
        background-color: #ffde00;
      }

      :nth-child(3) {
        background-color: #e7e7e5;
      }
    }
  }

  tbody {
    tr {
      :nth-child(odd) {
        td {
          :nth-child(1) {
            background-color: #f0f0f0;
          }

          :nth-child(2) {
            background-color: #fff8cc;
          }

          :nth-child(3) {
            background-color: #fafafa;
          }
        }
      }

      :nth-child(even) {
        td {
          :nth-child(1) {
            background-color: #fafafa;
          }

          :nth-child(2) {
            background-color: #fffce6;
          }

          :nth-child(3) {
            background-color: #fdfdfc;
          }
        }
      }
    }

    td {
      min-height: 45px;

      > * {
        vertical-align: middle;
      }

      :not(:first-of-type) {
        text-align: center;
      }
    }
  }

  th,
  td {
    box-sizing: border-box;
    padding: 10px;
    width: 33.33%;

    :first-of-type {
      min-width: 250px;
    }

    :not(:first-of-type) {
      min-width: 100px;
    }
  }

  @media (min-width: 800px) {
    thead {
      display: block;
      width: 100%;
    }

    tbody {
      display: block;
      width: 100%;
    }

    tr {
      display: block;
    }

    th,
    td {
      display: inline-block;

      :first-of-type {
        min-width: 0;
        width: 70%;
      }

      :not(:first-of-type) {
        min-width: 0;
        width: 15%;
      }
    }
  }
`

const NoteContainer = styled.div`
  margin-top: 16px;

  svg {
    margin-right: 8px;
    vertical-align: middle;
  }
`

const Note = styled.span`
  font-style: italic;
  line-height: 1;
  vertical-align: middle;
`

export const pageQuery = graphql`
  {
    logo: file(relativePath: { eq: "hutson-precision-ag-team-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    computerImage: file(relativePath: { eq: "computer.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    }
    heroImage: file(relativePath: { eq: "ag-data-bg.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default PrecisionAgPage
